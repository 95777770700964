import { TFunction } from 'i18next';

export function translateBackendErrors(
  errorKeys: string[],
  translate: TFunction<'translation', undefined>,
): string {
  const translatedErrors = errorKeys.map((errorKey) =>
    translate(`backend_validation_errors.${errorKey}`),
  );
  return translatedErrors.join('. ');
}
