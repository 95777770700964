export async function requestToken(
  resourceToken: string,
  payload: IRequestToken,
): Promise<IRequestTokenResult | IRequestTokenFailure> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const requestUrl = `${process.env.REACT_APP_BFF_URL}/tokens`;
  const requestTokenDto = mapToDto(payload);

  const requestOptions: RequestInit = {
    body: JSON.stringify(requestTokenDto),
    headers: headers,
    method: 'POST',
  };

  const response = await fetch(requestUrl, requestOptions);

  const serializedBody = await response.json();

  if (response.status === 403) {
    const result = serializedBody as IRequestTokenFailureDto;
    return {
      errorCode: result.detail,
    } as IRequestTokenFailure;
  }

  if (!response.ok) {
    throw new Error(serializedBody);
  } else {
    const result = serializedBody as IRequestTokenResultDto;
    return {
      redirectUrl: result.redirect_url,
      token: result.token,
    } as IRequestTokenResult;
  }
}

const mapToDto = (requestToken: IRequestToken): IRequestTokenDto => ({
  type: requestToken.type,
  ...{
    issuer: requestToken.issuer,
    token_data: {
      '3ds_method': requestToken.tokenData.threeDsMethod,
      '3ds_trxid': requestToken.tokenData.threeDsTransactionId,
      '3ds_version': requestToken.tokenData.threeDsVersion,
      response_code: requestToken.tokenData.responseCode,
      response_id: requestToken.tokenData.responseId,
    },
    value: requestToken.value,
  },
});

interface IRequestTokenDto {
  type: string;
  value: string;
  issuer: string;
  token_data: ITokenDataDto;
}

interface ITokenDataDto {
  [key: string]: string;
}

interface IRequestTokenResultDto {
  token: string;
  redirect_url?: string;
}

interface IRequestTokenFailureDto {
  detail: string;
}

interface IRequestToken {
  type: string;
  value: string;
  issuer: string;
  tokenData: ITokenData;
}

interface IRequestTokenResult {
  token: string;
  redirectUrl?: string;
}

interface IRequestTokenFailure {
  errorCode: string;
}

interface ITokenData {
  threeDsVersion: string;
  threeDsMethod: string;
  threeDsTransactionId: string;
  responseId: string;
  responseCode: string;
}
