import { t } from 'i18next';

import logo from '../../../../resources/images/logo-dark.png';

import './Header.scss';

const Header = (props: IProps) => (
  <div className="payment-header-container">
    <img className="payment-header-logo" src={props.logoUrl || logo} alt={props.companyName} />

    <div className="payment-header-summary">
      <p>{t('about_to_pay')}</p>
      <strong>{props.companyName}</strong>
      {props.description && <p>{props.description}</p>}
    </div>

    <div className="payment-header-amount">
      <span>{`${t('amount')}: `}</span>
      <strong>{`€${props.amount}`}</strong>
    </div>
  </div>
);

export default Header;

interface IProps {
  logoUrl: string;
  companyName: string;
  description?: string;
  amount: string;
}
