export async function resetPayment(resourceToken: string) {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const requestUrl = `${process.env.REACT_APP_BFF_URL}/payment/payment-method/reset`;
  const requestOptions: RequestInit = {
    headers: headers,
    method: 'POST',
  };

  const response = await fetch(requestUrl, requestOptions);

  if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  }
}
