import { Error } from '@mui/icons-material';

import './ErrorMsg.scss';

const ErrorMsg = (props: IProps) => (
  <div className="error-container">
    <Error />
    <p>{props.text}</p>
  </div>
);

export default ErrorMsg;

interface IProps {
  text: string;
}
