import { ILink, IPaymentResult, IPaymentResultDto } from './commonTypes';

export async function startPayment(link: ILink, resourceToken: string): Promise<IPaymentResult> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const requestOptions: RequestInit = {
    headers: headers,
    method: link.methods[0],
  };

  const response = await fetch(link.href, requestOptions);

  if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  } else {
    const startPaymentResultDto = (await response.json()) as IPaymentResultDto;
    const startPaymentResult = mapFromDto(startPaymentResultDto);

    return startPaymentResult;
  }
}

function mapFromDto(resultDto: IPaymentResultDto): IPaymentResult {
  return {
    links: resultDto._links,
    status: resultDto.status,
    workflow: resultDto.workflow,
  };
}
