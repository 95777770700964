import { IPaymentResult, IPaymentResultDto, PaymentStatus, PaymentType } from './commonTypes';

export async function getPayment(resourceToken: string): Promise<IGetPaymentResult> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const requestUrl = `${process.env.REACT_APP_BFF_URL}/payment`;
  const requestOptions: RequestInit = {
    headers: headers,
    method: 'GET',
  };

  const response = await fetch(requestUrl, requestOptions);

  if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  } else {
    const getPaymentResultDto = (await response.json()) as IGetPaymentResultDto;
    const getPaymentResult = mapFromDto(getPaymentResultDto);

    return getPaymentResult;
  }
}

function mapFromDto(resultDto: IGetPaymentResultDto): IGetPaymentResult {
  return {
    amountMinor: resultDto.amount_minor,
    branding: {
      format: resultDto.branding.format,
      merchant: resultDto.branding.merchant && {
        colors: resultDto.branding.merchant.colors,
        logoUrl: resultDto.branding.merchant.logo_url,
        name: resultDto.branding.merchant.name,
      },
      platform: {
        colors: resultDto.branding.platform.colors,
        logoUrl: resultDto.branding.platform.logo_url,
        name: resultDto.branding.platform.name,
      },
    },
    currency: resultDto.currency,
    description: resultDto.description,
    expiresAt: resultDto.expires_at,
    id: resultDto.id,
    links: resultDto._links,
    paymentMethodSelected: resultDto.payment_method_selected,
    paymentMethods: resultDto.payment_methods.map<IPaymentMethod>((item) => ({
      iconUrl: item.icon_url,
      name: item.name,
      type: item.type,
    })),
    requiresStoreCredentials: resultDto.requires_store_credentials,
    status: resultDto.status,
    tokenization: {
      merchantId: resultDto.tokenization.merchant_id,
    },
    workflow: resultDto.workflow,
  };
}

export enum BrandingFormat {
  PlatformOnly = 'platform_only',
  MerchantOnly = 'merchant_only',
  PlatformPrimary = 'platform_primary',
  MerchantPrimary = 'merchant_primary',
}

interface IGetPaymentResultDto extends IPaymentResultDto {
  id: string;
  amount_minor: number;
  currency: string;
  expires_at?: string;
  description?: string;
  branding: IBrandingConfigurationDto;
  payment_methods: IPaymentMethodDto[];
  payment_method_selected?: PaymentType;
  tokenization: ITokenizationDto;
  requires_store_credentials: boolean;
}

interface IBrandingConfigurationDto {
  format?: BrandingFormat;
  platform: IBrandingDto;
  merchant?: IBrandingDto;
}

interface IBrandingDto {
  name: string;
  logo_url: string;
  colors: IColors;
}

interface IPaymentMethodDto {
  type: PaymentType;
  name: string;
  icon_url: string;
}

interface ITokenizationDto {
  merchant_id: string;
}

interface IGetPaymentResult extends IPaymentResult {
  id: string;
  amountMinor: number;
  currency: string;
  expiresAt?: string;
  description?: string;
  branding: IBrandingConfiguration;
  paymentMethods: IPaymentMethod[];
  paymentMethodSelected?: PaymentType;
  status: PaymentStatus;
  tokenization: ITokenization;
  requiresStoreCredentials: boolean;
}

export interface IBrandingConfiguration {
  format?: BrandingFormat;
  platform: IBranding;
  merchant?: IBranding;
}

interface IBranding {
  name: string;
  logoUrl: string;
  colors: IColors;
}

export interface IPaymentMethod {
  type: PaymentType;
  name: string;
  iconUrl: string;
}

interface IColors {
  primary: string;
  secondary: string;
}

interface ITokenization {
  merchantId: string;
}
