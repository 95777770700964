import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useState } from 'react';
import cardValidator from 'card-validator';
import { InputAdornment, TextField } from '@mui/material';

import americanExpress from '../../../resources/images/cardTypes/american-express.svg';
import dinersClub from '../../../resources/images/cardTypes/diners-club.svg';
import discover from '../../../resources/images/cardTypes/discover.svg';
import elo from '../../../resources/images/cardTypes/elo.svg';
import hiper from '../../../resources/images/cardTypes/hiper.svg';
import jcb from '../../../resources/images/cardTypes/jcb.svg';
import maestro from '../../../resources/images/cardTypes/maestro.svg';
import mastercard from '../../../resources/images/cardTypes/mastercard.svg';
import unionpay from '../../../resources/images/cardTypes/unionpay.svg';
import visa from '../../../resources/images/cardTypes/visa.svg';

import './CardField.scss';

export enum CardType {
  Visa = 'visa',
  Mastercard = 'mastercard',
  AmericanExpress = 'american-express',
  DinersClub = 'diners-club',
  Discover = 'discover',
  Jcb = 'jcb',
  Unionpay = 'unionpay',
  Maestro = 'maestro',
  Mir = 'mir',
  Elo = 'elo',
  Hiper = 'hiper',
  Hipercard = 'hipercard',
}

const CardField = (props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
  const [state, setState] = useState<IState>({ cardNumber: '' });

  const getCardIcon = (cardType?: CardType): string | undefined => {
    switch (cardType) {
      case CardType.AmericanExpress:
        return americanExpress;
      case CardType.DinersClub:
        return dinersClub;
      case CardType.Discover:
        return discover;
      case CardType.Elo:
        return elo;
      case CardType.Hiper:
        return hiper;
      case CardType.Jcb:
        return jcb;
      case CardType.Maestro:
        return maestro;
      case CardType.Mastercard:
        return mastercard;
      case CardType.Unionpay:
        return unionpay;
      case CardType.Visa:
        return visa;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (props?.onCardNumberChange) {
      props.onCardNumberChange(state.cardNumber);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cardNumber]);

  const handleCardNumberChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputVal = event.target.value.replace(/ /g, ''); // Remove all the empty spaces in the input
    let inputNumbersOnly = inputVal.replace(/\D/g, ''); // Get only digits

    if (inputNumbersOnly.length > 16) {
      // If entered value has a length greater than 16 then take only the first 16 digits
      inputNumbersOnly = inputNumbersOnly.substring(0, 16);
    }

    // Get nd array of 4 digits per an element EX: ["4242", "4242", ...]
    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = '';
    if (splits) {
      spacedNumber = splits.join(' '); // Join all the splits with an empty space
    }

    const cardValidationResult = cardValidator.number(spacedNumber);

    const cardType = cardValidationResult.card?.type as CardType;
    const cardNiceType = cardValidationResult.card?.niceType;
    const cardTypeIcon = getCardIcon(cardType);

    setState((prev) => ({
      ...prev,
      cardNiceType: cardNiceType,
      cardNumber: spacedNumber,
      cardType: cardType,
      cardTypeIcon: cardTypeIcon,
    }));
  };

  return (
    <TextField
      id={props.id}
      inputRef={ref}
      className="card-field-container"
      label={props.label}
      value={state.cardNumber}
      onChange={handleCardNumberChange}
      placeholder="0000 0000 0000 0000"
      error={Boolean(props.errorMessage)}
      helperText={props.errorMessage}
      required={props.required}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      InputProps={{
        endAdornment: state.cardTypeIcon ? (
          <InputAdornment position="end">
            <img
              className="card-icon"
              src={state.cardTypeIcon}
              alt={state.cardType}
              title={state.cardNiceType}
            />
          </InputAdornment>
        ) : (
          <></>
        ),
      }}
    />
  );
};

export default forwardRef<HTMLInputElement, IProps>(CardField);

interface IProps {
  label: string;
  id?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string | null;
  onCardNumberChange?: (value: string) => void;
}

interface IState {
  cardNumber: string;
  cardType?: CardType;
  cardNiceType?: string;
  cardTypeIcon?: string;
}
