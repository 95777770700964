import { useTranslation } from 'react-i18next';

import logo from '../../../resources/images/logo.png';

import './Welcome.scss';

const Welcome = () => {
  const { t: translate } = useTranslation();

  return (
    <div className="welcome-page-container">
      <img src={logo} alt="Getpaid logo" />
      <p>{translate('welcome_message')}</p>
    </div>
  );
};

export default Welcome;
