export async function getCountries(resourceToken: string) {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const requestUrl = `${process.env.REACT_APP_BFF_URL}/countries`;
  const requestOptions: RequestInit = {
    headers: headers,
    method: 'GET',
  };

  const response = await fetch(requestUrl, requestOptions);

  if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  }

  const countryDtos = (await response.json()) as ICountryDto[];
  const countries = countryDtos.map<ICountry>((c) => mapFromDto(c));

  return countries;
}

function mapFromDto(dto: ICountryDto): ICountry {
  return {
    code: dto.code,
    name: dto.name,
  };
}

interface ICountryDto {
  code: string;
  name: string;
}

export interface ICountry {
  code: string;
  name: string;
}
