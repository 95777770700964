import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  ILink,
  ILinks,
  IWorkflow,
  LinkRelation,
  PaymentStatus,
  PaymentType,
} from '../../../services/commonTypes';
import {
  BrandingFormat,
  getPayment,
  IBrandingConfiguration,
  IPaymentMethod,
} from '../../../services/getPaymentService';
import { resetPayment } from '../../../services/resetPaymentMethodService';
import { startPayment } from '../../../services/startPaymentService';
import ErrorMsg from '../../shared/ErrorMsg/ErrorMsg';
import WarningMsg from '../../shared/WarningMsg/WarningMsg';

import Header from './Header/Header';
import PaymentMethodSwitcher from './PaymentMethodSwitcher/PaymentMethodSwitcher';
import Mandate from './SddMandate/SddMandate';

import './Payment.scss';

const PAYMENT_STATES = [
  PaymentStatus.Started,
  PaymentStatus.AuthenticationFailed,
  PaymentStatus.Declined,
];

const Payment = () => {
  const { t: translate } = useTranslation();
  const params = useParams();
  const resourceToken = params?.resourceToken ?? '';

  const [payment, setPayment] = useState<IPayment>();
  const [isLoading, setIsLoading] = useState(false);
  const [theme, setTheme] = useState(createTheme());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const [isToastErrorDisplayed, setIsToastErrorDisplayed] = useState(false);
  const [mandateLink, setMandateLink] = useState<ILink | undefined>(undefined);

  const showMandate =
    payment?.status === PaymentStatus.Authenticated &&
    payment?.workflow?.next === LinkRelation.Mandate;

  const getBranding = (brandingConfiguration: IBrandingConfiguration): IBranding => {
    if (
      !brandingConfiguration.format ||
      brandingConfiguration.format === BrandingFormat.PlatformOnly ||
      brandingConfiguration.format === BrandingFormat.PlatformPrimary
    ) {
      return {
        companyName: brandingConfiguration.platform.name,
        logoUrl: brandingConfiguration.platform.logoUrl,
        primaryColor: brandingConfiguration.platform.colors.primary,
        secondaryColor: brandingConfiguration.platform.colors.secondary,
      };
    } else if (
      brandingConfiguration.format === BrandingFormat.MerchantOnly ||
      brandingConfiguration.format === BrandingFormat.MerchantPrimary
    ) {
      return {
        companyName: brandingConfiguration.merchant?.name ?? '',
        logoUrl: brandingConfiguration.merchant?.logoUrl ?? '',
        primaryColor: brandingConfiguration.merchant?.colors.primary ?? '',
        secondaryColor: brandingConfiguration.merchant?.colors.secondary ?? '',
      };
    } else {
      throw new Error('Not supported');
    }
  };

  const isStatusSupported = (status: PaymentStatus) =>
    status === PaymentStatus.Initiated ||
    status === PaymentStatus.Started ||
    status === PaymentStatus.AuthenticationFailed ||
    status === PaymentStatus.Authenticated ||
    status === PaymentStatus.Declined;

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const getPaymentResult = await getPayment(resourceToken);
        const branding = getBranding(getPaymentResult.branding);

        let workflow = getPaymentResult.workflow;
        let links = getPaymentResult.links;
        let status = getPaymentResult.status;

        if (isStatusSupported(getPaymentResult.status)) {
          if (workflow?.next === LinkRelation.Start) {
            const startPaymentResult = await startPayment(
              getPaymentResult.links[workflow.next],
              resourceToken,
            );
            links = startPaymentResult.links;
            status = startPaymentResult.status;
            workflow = startPaymentResult.workflow;
          } else if (workflow?.next === LinkRelation.Mandate) {
            setMandateLink(links[LinkRelation.Mandate]);
          }

          setPayment({
            amount: (getPaymentResult.amountMinor / 100).toString(),
            branding: branding,
            description: getPaymentResult.description,
            displayRecurrentPaymentsAcceptance: getPaymentResult.requiresStoreCredentials,
            links: links,
            paymentId: getPaymentResult.id,
            paymentMethods: getPaymentResult.paymentMethods,
            selectedPaymentMethod: getPaymentResult.paymentMethodSelected,
            status: status,
            tokenizationMerchantId: getPaymentResult.tokenization.merchantId,
            workflow: workflow,
          });
          setTheme(
            createTheme({
              palette: {
                primary: { main: branding.primaryColor },
                secondary: { main: branding.secondaryColor },
              },
            }),
          );
          setIsLoading(false);
        } else {
          setIsLoading(false);
          if (getPaymentResult.status === PaymentStatus.Authenticating) {
            setWarningMessage(translate('warnings.payment_being_processed'));
          } else {
            setErrorMessage(translate('errors.payment_already_processed'));
          }
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setErrorMessage(translate('errors.initial_loading_unexpected_error'));
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnBack = async () => {
    try {
      await resetPayment(resourceToken);
    } catch (e) {
      console.log(e);
      setIsToastErrorDisplayed(true);
      return;
    }
    setPayment((prev) => ({ ...prev, status: PaymentStatus.Started }) as IPayment);
  };

  const updatePaymentStatus = (
    paymentStatus: PaymentStatus,
    workflowNext: string,
    links: ILinks,
  ) => {
    setPayment(
      (prev) =>
        ({
          ...prev,
          status: paymentStatus,
          workflow: { ...prev?.workflow, links: links, next: workflowNext },
        }) as IPayment,
    );
    if (workflowNext === LinkRelation.Mandate) {
      setMandateLink(links[LinkRelation.Mandate]);
    }
  };

  const handleHideToast = () => setIsToastErrorDisplayed(false);

  return (
    <div className="payment-container">
      {isLoading && !payment && (
        <div className="payment-container-loading">
          <CircularProgress size={70} />
        </div>
      )}

      {!isLoading && !errorMessage && payment && (
        <ThemeProvider theme={theme}>
          <div
            className="payment-container-loaded"
            style={{
              backgroundColor: payment.branding.primaryColor || '#253F66',
            }}
          >
            <div
              className={
                payment.status === PaymentStatus.Authenticated &&
                payment.workflow?.next === LinkRelation.Mandate
                  ? 'payment-content lg'
                  : 'payment-content'
              }
            >
              {showMandate && <BackButton onClick={handleOnBack} />}
              <Header
                amount={payment.amount}
                companyName={payment.branding.companyName}
                logoUrl={payment.branding.logoUrl}
                description={payment.description}
              />

              {PAYMENT_STATES.includes(payment.status) && (
                <PaymentMethodSwitcher
                  resourceToken={resourceToken}
                  paymentId={payment.paymentId}
                  paymentStatus={payment.status}
                  tokenizationMerchantId={payment.tokenizationMerchantId}
                  paymentMethods={payment.paymentMethods}
                  selectedPaymentMethod={payment.selectedPaymentMethod}
                  authorizeLink={payment.links?.[LinkRelation.Authorize]}
                  onPaymentStateUpdated={updatePaymentStatus}
                />
              )}
              {showMandate && (
                <Mandate resourceToken={resourceToken} createSddMandateLink={mandateLink} />
              )}
              {payment.displayRecurrentPaymentsAcceptance && (
                <div className="recurring-payments-legal-mention">
                  {translate('recurring_payments_legal_mention')}
                </div>
              )}
            </div>
          </div>
        </ThemeProvider>
      )}
      <Snackbar
        open={isToastErrorDisplayed}
        autoHideDuration={5000}
        onClose={handleHideToast}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Alert onClose={handleHideToast} severity="error" sx={{ width: '350px' }}>
          {translate('backend_validation_errors.unexpected_error')}
        </Alert>
      </Snackbar>

      {!isLoading && errorMessage && <ErrorMsg text={errorMessage} />}

      {!isLoading && warningMessage && <WarningMsg text={warningMessage} />}
    </div>
  );
};

const BackButton = ({ onClick }: { onClick: () => void }) => {
  const { t: translate } = useTranslation();

  return (
    <Button
      data-testid="backBtn"
      data-id="back-btn"
      variant="outlined"
      onClick={onClick}
      startIcon={<ChevronLeft />}
    >
      {translate('back')}
    </Button>
  );
};

export default Payment;

interface IPayment {
  paymentId: string;
  branding: IBranding;
  description?: string;
  amount: string;
  tokenizationMerchantId: string;
  paymentMethods: IPaymentMethod[];
  selectedPaymentMethod?: PaymentType;
  status: PaymentStatus;
  displayRecurrentPaymentsAcceptance: boolean;
  workflow: IWorkflow;
  links: ILinks;
}

interface IBranding {
  logoUrl: string;
  primaryColor: string;
  secondaryColor: string;
  companyName: string;
}
