import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Alert, Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { translateBackendErrors } from '../../../../helpers/paymentHelper';
import { SupportedBackendErrors } from '../../../../services/authorizePaymentService';
import {
  BankAccountType,
  IItem,
  ILink,
  RegistrationNumberCode,
  SddMandateField,
} from '../../../../services/commonTypes';
import { createSddMandate, ICreateSddMandate } from '../../../../services/createSddMandateService';
import { getCountries, ICountry } from '../../../../services/getCountriesService';
import {
  getSddMandateForm,
  IGetSddMandateFormResult,
} from '../../../../services/getSddMandateFormService';
import CountryField from '../../../shared/CountryField';
import SelectField from '../../../shared/SelectField';

import './SddMandate.scss';

const Mandate = (props: IProps) => {
  const { t: translate } = useTranslation();
  const initialState: IState = {
    countries: [],
    districtCourts: [],
    errors: {},
    fields: {},
    isLoadingForm: false,
    isSavingMandate: false,
    isToastErrorDisplayed: false,
    registrationNumberTypes: [],
  };
  const [state, setState] = useState<IState>(initialState);

  const handleFieldChange = (fieldName: string, value: string | Dayjs | null): void => {
    setState((prev) => ({
      ...prev,
      fields: { ...prev.fields, [fieldName]: value },
    }));
  };

  const isFormValid = (): boolean => {
    const errors: IErrors = {};

    const minLengthName = 2;
    const maxLengthName = 50;

    const minLengthCity = 2;
    const maxLengthCity = 50;

    if (isFieldSupported(SddMandateField.FirstName)) {
      if (!state.fields.firstName) {
        errors.firstName = translate('validation_errors.required');
      } else if (state.fields.firstName.length > maxLengthName) {
        errors.firstName = translate('validation_errors.max_length_reached', {
          max: maxLengthName,
        });
      } else if (state.fields.firstName.length < minLengthName) {
        errors.firstName = translate('validation_errors.min_length_unreached', {
          min: minLengthName,
        });
      }
    }

    if (isFieldSupported(SddMandateField.LastName)) {
      if (!state.fields.lastName) {
        errors.lastName = translate('validation_errors.required');
      } else if (state.fields.lastName.length > maxLengthName) {
        errors.lastName = translate('validation_errors.max_length_reached', {
          max: maxLengthName,
        });
      } else if (state.fields.lastName.length < minLengthName) {
        errors.lastName = translate('validation_errors.min_length_unreached', {
          min: minLengthName,
        });
      }
    }

    if (isFieldSupported(SddMandateField.BirthDate)) {
      if (!state.fields.birthDate) {
        errors.birthDate = translate('validation_errors.required');
      } else if (state.fields.birthDate.isAfter(dayjs().subtract(16, 'years'))) {
        errors.birthDate = translate('validation_errors.not_reached_min_age', { age: 16 });
      }
    }

    if (isFieldSupported(SddMandateField.BirthPlace)) {
      if (!state.fields.birthPlaceCity) {
        errors.birthPlaceCity = translate('validation_errors.required');
      } else if (state.fields.birthPlaceCity.length > maxLengthCity) {
        errors.birthPlaceCity = translate('validation_errors.max_length_reached', {
          max: maxLengthCity,
        });
      } else if (state.fields.birthPlaceCity.length < minLengthCity) {
        errors.birthPlaceCity = translate('validation_errors.min_length_unreached', {
          min: minLengthCity,
        });
      }

      if (!state.fields.birthPlaceCountry) {
        errors.birthPlaceCountry = translate('validation_errors.required');
      }
    }

    if (isFieldSupported(SddMandateField.PostalAddress)) {
      const maxLengthLine = 300;

      if (!state.fields.line1) {
        errors.line1 = translate('validation_errors.required');
      } else if (state.fields.line1.length > maxLengthLine) {
        errors.line1 = translate('validation_errors.max_length_reached', {
          max: maxLengthLine,
        });
      }

      if (state.fields.line2 && state.fields.line2.length > maxLengthLine) {
        errors.line2 = translate('validation_errors.max_length_reached', {
          max: maxLengthLine,
        });
      }

      const maxLengthHouseNumber = 50;

      if (!state.fields.houseNumber) {
        errors.houseNumber = translate('validation_errors.required');
      } else if (state.fields.houseNumber.length > maxLengthLine) {
        errors.houseNumber = translate('validation_errors.max_length_reached', {
          max: maxLengthHouseNumber,
        });
      }

      const minLengthTownCity = 2;
      const maxLengthTownCity = 50;

      if (!state.fields.townCity) {
        errors.townCity = translate('validation_errors.required');
      } else if (state.fields.townCity.length > maxLengthTownCity) {
        errors.townCity = translate('validation_errors.max_length_reached', {
          max: maxLengthTownCity,
        });
      } else if (state.fields.townCity.length < minLengthTownCity) {
        errors.townCity = translate('validation_errors.min_length_unreached', {
          min: minLengthTownCity,
        });
      }

      const maxLengthState = 300;

      if (state.fields.state && state.fields.state.length > maxLengthState) {
        errors.state = translate('validation_errors.max_length_reached', {
          max: maxLengthState,
        });
      }

      const maxLengthZip = 16;

      if (!state.fields.zip) {
        errors.zip = translate('validation_errors.required');
      } else if (state.fields.zip.length > maxLengthZip) {
        errors.zip = translate('validation_errors.max_length_reached', {
          max: maxLengthZip,
        });
      }
    }

    if (state.mandateForm?.type === BankAccountType.Business) {
      if (isFieldSupported(SddMandateField.CompanyName)) {
        const minLengthCompanyName = 2;
        const maxLengthCompanyName = 150;

        if (!state.fields.companyName) {
          errors.companyName = translate('validation_errors.required');
        } else if (state.fields.companyName.length > maxLengthCompanyName) {
          errors.companyName = translate('validation_errors.max_length_reached', {
            max: maxLengthCompanyName,
          });
        } else if (state.fields.companyName.length < minLengthCompanyName) {
          errors.companyName = translate('validation_errors.min_length_unreached', {
            min: minLengthCompanyName,
          });
        }
      }

      if (
        isFieldSupported(SddMandateField.RegistrationNumberCode) &&
        !state.fields.registrationNumberCode
      ) {
        errors.registrationNumberCode = translate('validation_errors.required');
      } else {
        if (
          isFieldSupported(SddMandateField.RegistrationNumber) &&
          !state.fields.registrationNumber
        ) {
          errors.registrationNumber = translate('validation_errors.required');
        }

        if (state.districtCourts.length > 0 && !state.fields.districtCourtCode) {
          errors.districtCourtCode = translate('validation_errors.required');
        }
      }
    }

    setState((prev) => ({ ...prev, errors: errors }));

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = isFormValid();
    if (!isValid) {
      return;
    }

    const createMandateForm: ICreateSddMandate = {
      birthDate: state.fields.birthDate && dayjs(state.fields.birthDate).format('YYYY-MM-DD'),
      birthPlace: isFieldSupported(SddMandateField.BirthPlace)
        ? {
            city: state.fields.birthPlaceCity,
            country: state.fields.birthPlaceCountry,
          }
        : undefined,
      companyName: state.fields.companyName,
      districtCourtCode: state.fields.districtCourtCode,
      firstName: state.fields.firstName,
      lastName: state.fields.lastName,
      postalAddress: isFieldSupported(SddMandateField.PostalAddress)
        ? {
            line1: state.fields.line1 ?? '',
            line2: state.fields.line2,
            number: state.fields.houseNumber ?? '',
            state: state.fields.state,
            townCity: state.fields.townCity ?? '',
            zip: state.fields.zip ?? '',
          }
        : undefined,
      registrationNumber: state.fields.registrationNumber,
      registrationNumberCode: state.fields.registrationNumberCode,
      type: state.mandateForm?.type,
    };

    setState((prev) => ({ ...prev, isSavingMandate: true }));

    try {
      if (!props.createSddMandateLink) throw new Error('createSddMandateLink is null');

      const createSddMandateResult = await createSddMandate(
        props.createSddMandateLink,
        props.resourceToken,
        createMandateForm,
      );

      if ('errors' in createSddMandateResult) {
        const errors: IErrors = {};

        if (createSddMandateResult.errors[SupportedBackendErrors.RegistrationNumber]) {
          const registrationNumberErrorMessage = translateBackendErrors(
            createSddMandateResult.errors[SupportedBackendErrors.RegistrationNumber],
            translate,
          );
          errors.registrationNumber = registrationNumberErrorMessage;
        } else {
          throw new Error(
            `Unknown error when validating the data: ${createSddMandateResult.errors}`,
          );
        }

        setState((prev) => ({
          ...prev,
          errors: errors,
          isSavingMandate: false,
        }));
      } else {
        if (
          createSddMandateResult.workflow.next &&
          createSddMandateResult.links[createSddMandateResult.workflow.next]
        ) {
          const redirectionLink =
            createSddMandateResult.links[createSddMandateResult.workflow.next];
          window.location.href = redirectionLink.href;
        } else {
          throw new Error('Inconsistency at the payment workflow and links.');
        }
      }
    } catch (error) {
      console.error(error);
      setState((prev) => ({
        ...prev,
        isSavingMandate: false,
        isToastErrorDisplayed: true,
        toastErrorMessage: translate('errors.sdd_mandate_generic'),
      }));
    }
  };

  const handleHideToast = (): void =>
    setState((prev) => ({ ...prev, isToastErrorDisplayed: false }));

  const getRegistrationNumberPlaceHolder = (
    registrationNumberCode: RegistrationNumberCode | undefined,
  ): string => {
    switch (registrationNumberCode) {
      case RegistrationNumberCode.DeHrb:
        return 'HRB 00000';
      case RegistrationNumberCode.DeHra:
        return 'HRA 0000';
      case RegistrationNumberCode.DeVr:
        return 'VR 00000';
      case RegistrationNumberCode.SiTin:
        return '10000000';
      case RegistrationNumberCode.EeKmkr:
        return '00000000';
      case RegistrationNumberCode.Vat:
        return 'DE00000000'; //TODO: the contry code hardcoded temporally, it should come from the backend
      case RegistrationNumberCode.LuRcs:
        return 'B00000';
      default:
        return '';
    }
  };

  const getRegistrationNumberLabel = (
    registrationNumberCode: RegistrationNumberCode | undefined,
  ): string => {
    const registrationNumber = state.registrationNumberTypes.find(
      (rn) => rn.value === registrationNumberCode,
    );
    if (registrationNumber) {
      return registrationNumber.description;
    } else {
      return translate('form_labels.registration_number');
    }
  };

  const isFieldSupported = (field: SddMandateField): boolean =>
    Boolean(state.mandateForm && state.mandateForm.fields.some((f) => f === field));

  const isAnyCompanyFieldSupported = (): boolean =>
    isFieldSupported(SddMandateField.CompanyName) ||
    isFieldSupported(SddMandateField.RegistrationNumberCode) ||
    isFieldSupported(SddMandateField.RegistrationNumber);

  const isAnyPersonalFieldSupported = (): boolean =>
    isFieldSupported(SddMandateField.FirstName) ||
    isFieldSupported(SddMandateField.LastName) ||
    isFieldSupported(SddMandateField.BirthDate) ||
    isFieldSupported(SddMandateField.BirthPlace);

  useEffect(() => {
    getCountries(props.resourceToken).then((countries) => {
      setState((prev) => ({ ...prev, countries: countries }));
    });
  }, [props.resourceToken]);

  useEffect(() => {
    const districtCourts = state.mandateForm?.registrationNumbers?.find(
      (rn) => rn.code === state.fields.registrationNumberCode,
    )?.districtCourtCode.required
      ? state.mandateForm?.districtCourts
      : [];
    const districtCourtItems = districtCourts
      ? districtCourts.map<IItem>((dc) => ({ description: dc.name, value: dc.code }))
      : [];

    setState((prev) => ({ ...prev, districtCourts: districtCourtItems }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fields.registrationNumberCode]);

  useEffect(() => {
    setState((prev) => ({ ...prev, isLoadingForm: true }));

    getSddMandateForm(props.resourceToken)
      .then((mandateForm) => {
        const registrationNumberTypes = mandateForm.registrationNumbers
          ? mandateForm.registrationNumbers.map<IItem>((rn) => ({
              description: rn.name,
              value: rn.code,
            }))
          : [];

        setState((prev) => ({
          ...prev,
          isLoadingForm: false,
          mandateForm: mandateForm,
          registrationNumberTypes: registrationNumberTypes,
        }));
      })
      .catch((error) => {
        console.error(error);
        setState((prev) => ({
          ...prev,
          isLoadingForm: false,
          isToastErrorDisplayed: true,
          toastErrorMessage: translate('errors.sdd_mandate_form_generic'),
        }));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sdd-mandate-container">
      {state.isLoadingForm && !state.mandateForm && (
        <div className="sdd-mandate-container-loading">
          <CircularProgress data-testid="loader" size={50} />
        </div>
      )}

      {!state.isLoadingForm && state.mandateForm && (
        <>
          <p>{state.mandateForm.mandateLegalMention}</p>

          {isAnyPersonalFieldSupported() && (
            <>
              <h2>{translate('sdd_mandate.personal_data')}</h2>

              <div className="sdd-mandate-form-section">
                {isFieldSupported(SddMandateField.FirstName) && (
                  <TextField
                    id="firstName"
                    name="firstName"
                    label={translate('form_labels.first_name')}
                    variant="outlined"
                    defaultValue={state.fields.firstName}
                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                    error={Boolean(state.errors.firstName)}
                    helperText={state.errors.firstName}
                    disabled={state.isSavingMandate}
                    required
                  />
                )}

                {isFieldSupported(SddMandateField.LastName) && (
                  <TextField
                    id="lastName"
                    name="lastName"
                    label={translate('form_labels.last_name')}
                    variant="outlined"
                    defaultValue={state.fields.lastName}
                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                    error={Boolean(state.errors.lastName)}
                    helperText={state.errors.lastName}
                    disabled={state.isSavingMandate}
                    required
                  />
                )}

                {isFieldSupported(SddMandateField.BirthDate) && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={translate('form_labels.birth_date') + ' *'}
                      format="DD/MM/YYYY"
                      defaultValue={state.fields.birthDate}
                      onChange={(value) => {
                        handleFieldChange('birthDate', value);
                      }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            required: true,
                          },
                          disabled: state.isSavingMandate,
                          error: Boolean(state.errors.birthDate),
                          helperText: state.errors.birthDate,
                          id: 'birthDate',
                          name: 'birthDate',
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}

                {isFieldSupported(SddMandateField.BirthPlace) && (
                  <TextField
                    id="birthPlaceCity"
                    name="birthPlaceCity"
                    label={translate('form_labels.birth_place_city')}
                    variant="outlined"
                    defaultValue={state.fields.birthPlaceCity}
                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                    error={Boolean(state.errors.birthPlaceCity)}
                    helperText={state.errors.birthPlaceCity}
                    disabled={state.isSavingMandate}
                    required
                  />
                )}

                {isFieldSupported(SddMandateField.BirthPlace) && (
                  <CountryField
                    id="birthPlaceCountry"
                    name="birthPlaceCountry"
                    label={translate('form_labels.birth_place_country')}
                    value={state.fields.birthPlaceCountry}
                    errorMessage={state.errors.birthPlaceCountry}
                    items={state.countries}
                    onChange={(name, value) => handleFieldChange(name, value)}
                    disabled={state.isSavingMandate}
                    required
                  />
                )}
              </div>
            </>
          )}

          {isFieldSupported(SddMandateField.PostalAddress) && (
            <>
              <h2>{translate('sdd_mandate.address')}</h2>
              <div className="sdd-mandate-form-section">
                <TextField
                  id="line1"
                  name="line1"
                  label={translate('form_labels.line_1')}
                  variant="outlined"
                  defaultValue={state.fields.line1}
                  onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                  error={Boolean(state.errors.line1)}
                  helperText={state.errors.line1}
                  disabled={state.isSavingMandate}
                  required
                />

                <TextField
                  id="line2"
                  name="line2"
                  label={translate('form_labels.line_2')}
                  variant="outlined"
                  defaultValue={state.fields.line2}
                  onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                  error={Boolean(state.errors.line2)}
                  helperText={state.errors.line2}
                  disabled={state.isSavingMandate}
                />

                <TextField
                  id="houseNumber"
                  name="houseNumber"
                  label={translate('form_labels.house_number')}
                  variant="outlined"
                  defaultValue={state.fields.houseNumber}
                  onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                  error={Boolean(state.errors.houseNumber)}
                  helperText={state.errors.houseNumber}
                  disabled={state.isSavingMandate}
                  required
                />

                <TextField
                  id="townCity"
                  name="townCity"
                  label={translate('form_labels.city')}
                  variant="outlined"
                  defaultValue={state.fields.townCity}
                  onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                  error={Boolean(state.errors.townCity)}
                  helperText={state.errors.townCity}
                  disabled={state.isSavingMandate}
                  required
                />

                <TextField
                  id="state"
                  name="state"
                  label={translate('form_labels.state')}
                  variant="outlined"
                  defaultValue={state.fields.state}
                  onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                  error={Boolean(state.errors.state)}
                  helperText={state.errors.state}
                  disabled={state.isSavingMandate}
                />

                <TextField
                  id="zip"
                  name="zip"
                  label={translate('form_labels.zip')}
                  variant="outlined"
                  defaultValue={state.fields.zip}
                  onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                  error={Boolean(state.errors.zip)}
                  helperText={state.errors.zip}
                  disabled={state.isSavingMandate}
                  required
                />
              </div>
            </>
          )}

          {state.mandateForm.type === BankAccountType.Business && isAnyCompanyFieldSupported() && (
            <>
              <h2>{translate('sdd_mandate.company_information')}</h2>
              <div className="sdd-mandate-form-section">
                {isFieldSupported(SddMandateField.CompanyName) && (
                  <TextField
                    id="companyName"
                    name="companyName"
                    label={translate('form_labels.company_name')}
                    variant="outlined"
                    defaultValue={state.fields.companyName}
                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                    error={Boolean(state.errors.companyName)}
                    helperText={state.errors.companyName}
                    disabled={state.isSavingMandate}
                    required
                  />
                )}

                {isFieldSupported(SddMandateField.RegistrationNumberCode) && (
                  <SelectField
                    id="registrationNumberCode"
                    name="registrationNumberCode"
                    label={translate('form_labels.registration_number_type')}
                    value={state.fields.registrationNumberCode}
                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                    errorMessage={state.errors?.registrationNumberCode}
                    items={state.registrationNumberTypes}
                    disabled={state.isSavingMandate}
                    required
                  />
                )}

                {isFieldSupported(SddMandateField.RegistrationNumber) &&
                  state.fields.registrationNumberCode && (
                    <TextField
                      id="registrationNumber"
                      name="registrationNumber"
                      label={getRegistrationNumberLabel(state.fields.registrationNumberCode)}
                      placeholder={getRegistrationNumberPlaceHolder(
                        state.fields.registrationNumberCode,
                      )}
                      variant="outlined"
                      defaultValue={state.fields.registrationNumber}
                      onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                      error={Boolean(state.errors.registrationNumber)}
                      helperText={state.errors.registrationNumber}
                      disabled={state.isSavingMandate}
                      required
                    />
                  )}

                {state.districtCourts.length > 0 && (
                  <SelectField
                    id="districtCourtCode"
                    name="districtCourtCode"
                    label={translate('form_labels.district_court')}
                    value={state.fields.districtCourtCode}
                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                    errorMessage={state.errors?.districtCourtCode}
                    items={state.districtCourts}
                    disabled={state.isSavingMandate}
                    required
                  />
                )}
              </div>
            </>
          )}

          <div className="button-container">
            <Button
              id="saveBtn"
              data-id="save-mandate-btn"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubmit}
              disabled={state.isSavingMandate}
            >
              {state.isSavingMandate ? (
                <span style={{ marginRight: 10 }}>{translate('buttons.confirming')}</span>
              ) : (
                translate('buttons.confirm_mandate')
              )}
              {state.isSavingMandate && <CircularProgress size={17} color="inherit" />}
            </Button>
          </div>

          <Snackbar
            open={state.isToastErrorDisplayed}
            autoHideDuration={5000}
            onClose={handleHideToast}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <Alert onClose={handleHideToast} severity="error" sx={{ width: '350px' }}>
              {state.toastErrorMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
};

export default Mandate;

interface IProps {
  createSddMandateLink: ILink | undefined;
  resourceToken: string;
}

interface IState {
  fields: IFields;
  errors: IErrors;
  registrationNumberTypes: IItem[];
  countries: ICountry[];
  districtCourts: IItem[];
  mandateForm?: IGetSddMandateFormResult;
  isToastErrorDisplayed: boolean;
  toastErrorMessage?: string | null;
  isLoadingForm: boolean;
  isSavingMandate: boolean;
}

interface IFields {
  firstName?: string;
  lastName?: string;
  birthDate?: Dayjs;
  birthPlaceCity?: string;
  birthPlaceCountry?: string;
  line1?: string;
  line2?: string;
  houseNumber?: string;
  townCity?: string;
  state?: string;
  zip?: string;
  companyName?: string;
  registrationNumberCode?: RegistrationNumberCode;
  registrationNumber?: string;
  districtCourtCode?: string;
}

interface IErrors {
  firstName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  birthPlaceCity?: string | null;
  birthPlaceCountry?: string | null;
  line1?: string | null;
  line2?: string | null;
  houseNumber?: string | null;
  townCity?: string | null;
  state?: string | null;
  zip?: string | null;
  companyName?: string | null;
  registrationNumberCode?: string | null;
  registrationNumber?: string | null;
  districtCourtCode?: string | null;
}
