import { Warning } from '@mui/icons-material';

import './WarningMsg.scss';

const WarningMsg = (props: IProps) => (
  <div className="warning-container">
    <Warning />
    <p>{props.text}</p>
  </div>
);

export default WarningMsg;

interface IProps {
  text: string;
}
