import { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';

import { ICountry } from '../../services/getCountriesService';

const CountryField = (props: IProps) => {
  const [state, setState] = useState<IState>({ selectedCountry: null });

  const handleChange = (event: SyntheticEvent<Element, Event>, value: ICountry | null) => {
    const newValue = value?.code ?? '';
    props.onChange(props.name, newValue);
  };

  useEffect(() => {
    let selectedCountry: ICountry | null;

    if (props.value) {
      const country = props.items.find((c) => c.code === props.value);
      if (!country) {
        throw new Error('Country is not supported');
      }

      selectedCountry = country;
    } else {
      selectedCountry = null;
    }

    setState((prev) => ({
      ...prev,
      selectedCountry: selectedCountry,
    }));
  }, [props.value, props.items]);

  return (
    <Autocomplete
      id={props.id}
      options={props.items}
      autoHighlight
      disabled={props.disabled}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { flexShrink: 0, mr: 2 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.name}
        </Box>
      )}
      value={state.selectedCountry}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          id={props.id}
          name={props.name}
          label={props.label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          required={props.required}
          error={Boolean(props.errorMessage)}
          helperText={props.errorMessage}
        />
      )}
    />
  );
};

export default CountryField;

interface IProps {
  id: string;
  name: string;
  label: string;
  value?: string;
  required?: boolean;
  errorMessage?: string | undefined | null;
  disabled?: boolean;
  items: ICountry[];
  onChange: (fiedlName: string, value: string) => void;
}

interface IState {
  selectedCountry: ICountry | null;
}
