import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

const connectionString = process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING;
const reactPlugin = new ReactPlugin();
const clickPlugin = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
};
const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    distributedTracingMode: DistributedTracingModes.W3C,
    enableCorsCorrelation: true,
    extensionConfig: {
      [clickPlugin.identifier]: clickPluginConfig,
    },
    extensions: [reactPlugin, clickPlugin],
  },
});

appInsights.addTelemetryInitializer((envelope) => {
  if (envelope.baseData) {
    envelope.baseData.uri = window.location.pathname.substring(
      0,
      window.location.pathname.lastIndexOf('/'),
    );
  }
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export { appInsights, reactPlugin };
