export async function tokenizeCard(
  paymentData: IPaymentData,
  tokenizationMerchanId: string,
  useSimulator?: boolean,
): Promise<ITokenizeCardResult> {
  const formData = new FormData();
  let requestUrl: string;

  const isSimulatorSupported =
    process.env.REACT_APP_SIMULATOR_SWITCH_ENABLED?.toLowerCase() === 'true';

  if (useSimulator && isSimulatorSupported) {
    requestUrl = `${process.env.REACT_APP_SIMULATOR_BASE_URL}/keypayment/rest/v2/store`;
    formData.append('Statickey', `${process.env.REACT_APP_SIMULATOR_STATIC_KEY}`);
  } else {
    requestUrl = `${process.env.REACT_APP_FINARO_BASE_URL}/keypayment/rest/v2/store`;
    formData.append('Statickey', `${process.env.REACT_APP_FINARO_STATIC_KEY}`);
  }

  formData.append('M', tokenizationMerchanId);
  formData.append('RequestID', paymentData.paymentId);
  formData.append('b1', paymentData.cardNumber);
  formData.append('b3', paymentData.expiryMonth);
  formData.append('b4', paymentData.expiryYear);
  formData.append('b5', paymentData.cvv);
  formData.append('c1', paymentData.cardholderName);

  const requestOptions: RequestInit = {
    body: formData,
    headers: new Headers(),
    method: 'POST',
  };

  const response = await fetch(requestUrl, requestOptions);

  if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  } else {
    const tokenizeResultDto = await response.json();
    const tokenizeResult: ITokenizeCardResult = {
      merchantId: tokenizeResultDto['M'],
      pKey: tokenizeResultDto['PKey'],
      responseCode: tokenizeResultDto['z2'],
      responseCodeDescription: tokenizeResultDto['z3'],
      responseId: tokenizeResultDto['ResponseID'],
      threeDsMethod: tokenizeResultDto['3ds_method'],
      threeDsTransactionId: tokenizeResultDto['3ds_trxid'],
      threeDsVersion: tokenizeResultDto['3ds_version'],
    };

    return tokenizeResult;
  }
}

export interface IPaymentData {
  paymentId: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
  cardholderName: string;
}

interface ITokenizeCardResult {
  merchantId: string;
  responseId: string;
  pKey: string;
  responseCode: string;
  responseCodeDescription: string;
  threeDsMethod: string;
  threeDsVersion: string;
  threeDsTransactionId: string;
}
