import { BankAccountType, RegistrationNumberCode, SddMandateField } from './commonTypes';

export async function getSddMandateForm(resourceToken: string): Promise<IGetSddMandateFormResult> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const requestUrl = `${process.env.REACT_APP_BFF_URL}/payment/sdd-mandate/form`;
  const requestOptions: RequestInit = {
    headers: headers,
    method: 'GET',
  };

  const response = await fetch(requestUrl, requestOptions);

  if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  } else {
    const resultDto = (await response.json()) as IGetSddMandateFormResultDto;
    const result = mapFromDto(resultDto);

    return result;
  }
}

const mapFromDto = (dto: IGetSddMandateFormResultDto): IGetSddMandateFormResult => ({
  districtCourts: dto.district_courts,
  fields: dto.fields,
  mandateLegalMention: dto.mandate_legal_mention,
  registrationNumbers: dto.registration_numbers?.map<IRegistrationNumber>((rn) => ({
    code: rn.code,
    districtCourtCode: rn.district_court_code,
    name: rn.name,
  })),
  type: dto.type,
});

// Types

export interface IGetSddMandateFormResult {
  type: BankAccountType;
  registrationNumbers?: IRegistrationNumber[];
  districtCourts?: IDistrictCourt[];
  mandateLegalMention: string;
  fields: SddMandateField[];
}

interface IRegistrationNumber {
  code: RegistrationNumberCode;
  name: string;
  districtCourtCode: IFormField;
}

interface IFormField {
  required: boolean;
}

interface IDistrictCourt {
  code: string;
  name: string;
}

// DTO types (in snake_case)

interface IGetSddMandateFormResultDto {
  type: BankAccountType;
  registration_numbers?: IRegistrationNumberDto[];
  district_courts?: IDistrictCourt[];
  mandate_legal_mention: string;
  fields: SddMandateField[];
}

interface IRegistrationNumberDto {
  code: RegistrationNumberCode;
  name: string;
  district_court_code: IFormField;
}
