import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Payment from './components/pages/Payment/Payment';
import Welcome from './components/pages/Welcome/Welcome';
import deTranslations from './resources/lang/de.json';
import enTranslations from './resources/lang/en.json';
import esTranslations from './resources/lang/es.json';
import { reactPlugin } from './services/applicationInsightsService';

import './App.scss';

const App = () => {
  i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      detection: {
        order: ['navigator'],
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      resources: {
        de: { translation: deTranslations },
        en: { translation: enTranslations },
        es: { translation: esTranslations },
      },
    });
  return (
    <div className="app-container">
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/:resourceToken" element={<Payment />} />
          </Routes>
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </div>
  );
};

export default App;
